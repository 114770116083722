import { CountriesRepository } from "../../domain/localization/CountriesRepository";
import Country from "../../domain/localization/Country";

export class InMemoryCountriesRepository implements CountriesRepository {
  countries = [
    { key: "AF", text: "Afghanistan", value: "Afghanistan" },
    { key: "AL", text: "Albania", value: "Albania" },
    { key: "DZ", value: "Algeria", text: "Algeria" },
    { key: "AS", value: "American Samoa", text: "American Samoa" },
    { key: "AD", value: "Andorra", text: "Andorra" },
    { key: "AO", value: "Angola", text: "Angola" },
    { key: "AI", value: "Anguilla", text: "Anguilla", description: "AVAILABLE" },
    { key: "AG", value: "Antigua and Barbuda", text: "Antigua and Barbuda" },
    { key: "AR", value: "Argentina", text: "Argentina" },
    { key: "AM", value: "Armenia", text: "Armenia" },
    { key: "AW", value: "Aruba", text: "Aruba" },
    { key: "AU", value: "Australia", text: "Australia", description: "AVAILABLE" },
    { key: "AT", value: "Austria", text: "Austria", description: "AVAILABLE" },
    { key: "AZ", value: "Azerbaijan", text: "Azerbaijan" },
    { key: "BS", value: "Bahamas", text: "Bahamas" },
    { key: "BH", value: "Bahrein", text: "Bahrein" },
    { key: "BD", value: "Bangladesh", text: "Bangladesh" },
    { key: "BB", value: "Barbados", text: "Barbados" },
    { key: "BY", value: "Belarus", text: "Belarus" },
    { key: "BE", value: "Belgium", text: "Belgium", description: "AVAILABLE" },
    { key: "BZ", value: "Belize", text: "Belize" },
    { key: "BJ", value: "Benin", text: "Benin" },
    { key: "BM", value: "Bermuda", text: "Bermuda", description: "AVAILABLE" },
    { key: "BT", value: "Bhutan", text: "Bhutan" },
    { key: "BO", value: "Bolivia", text: "Bolivia" },
    { key: "BA", value: "Bosnia And Herzegovina", text: "Bosnia And Herzegovina" },
    { key: "BW", value: "Botswana", text: "Botswana" },
    { key: "BV", value: "Bouvet Island", text: "Bouvet Island" },
    { key: "BR", value: "Brazil", text: "Brazil" },
    { key: "IO", value: "British Indian Ocean Territory", text: "British Indian Ocean Territory" },
    { key: "BN", value: "Brunei Darussalam", text: "Brunei Darussalam" },
    { key: "BG", value: "Bulgaria", text: "Bulgaria" },
    { key: "BF", value: "Burkina Faso", text: "Burkina Faso" },
    { key: "BI", value: "Burundi", text: "Burundi" },
    { key: "KH", value: "Cambodia", text: "Cambodia" },
    { key: "CM", value: "Cameroon", text: "Cameroon" },
    { key: "CA", value: "Canada", text: "Canada", description: "AVAILABLE" },
    { key: "CV", value: "Cape Verde", text: "Cape Verde" },
    { key: "KY", value: "Cayman Islands", text: "Cayman Islands", description: "AVAILABLE" },
    { key: "CF", value: "Central African Republic", text: "Central African Republic" },
    { key: "TD", value: "Chad", text: "Chad" },
    { key: "CL", value: "Chile", text: "Chile" },
    { key: "CN", value: "China", text: "China" },
    { key: "CX", value: "Christmas Islands", text: "Christmas Islands" },
    { key: "CC", value: "Cocos Islands", text: "Cocos Islands" },
    { key: "CO", value: "Colombia", text: "Colombia" },
    { key: "KM", value: "Comoros", text: "Comoros" },
    { key: "CG", value: "Congo", text: "Congo" },
    { key: "CD", value: "The Democratic Republic Of Congo", text: "The Democratic Republic Of Congo" },
    { key: "CK", value: "Cook Islands", text: "Cook Islands" },
    { key: "CR", value: "Costa Rica", text: "Costa Rica" },
    { key: "HR", value: "Croatia", text: "Croatia" },
    { key: "CU", value: "Cuba", text: "Cuba" },
    { key: "CY", value: "Cyprus", text: "Cyprus", description: "AVAILABLE" },
    { key: "CZ", value: "Czech Republic", text: "Czech Republic" },
    { key: "DK", value: "Denmark", text: "Denmark" },
    { key: "DJ", value: "Djibouti", text: "Djibouti" },
    { key: "DM", value: "Dominica", text: "Dominica" },
    { key: "DO", value: "Dominican Republic", text: "Dominican Republic" },
    { key: "EC", value: "Ecuador", text: "Ecuador" },
    { key: "EG", value: "Egypt", text: "Egypt" },
    { key: "SV", value: "El Salvador", text: "El Salvador" },
    { key: "GQ", value: "Equatorial Guinea", text: "Equatorial Guinea" },
    { key: "ER", value: "Eritrea", text: "Eritrea" },
    { key: "EE", value: "Estonia", text: "Estonia", description: "AVAILABLE" },
    { key: "ET", value: "Ethiopia", text: "Ethiopia" },
    { key: "FK", value: "Falkland Islands", text: "Falkland Islands" },
    { key: "FO", value: "Faeroe Islands", text: "Faeroe Islands" },
    { key: "FJ", value: "Fiji", text: "Fiji" },
    { key: "FI", value: "Finland", text: "Finland", description: "AVAILABLE" },
    { key: "FR", value: "France", text: "France", description: "AVAILABLE" },
    { key: "GF", value: "French Guiana", text: "French Guiana" },
    { key: "PF", value: "French Polynesia", text: "French Polynesia" },
    { key: "TF", value: "French Southern Territories", text: "French Southern Territories" },
    { key: "GA", value: "Gabon", text: "Gabon" },
    { key: "GM", value: "Gambia", text: "Gambia" },
    { key: "GE", value: "Georgia", text: "Georgia" },
    { key: "DE", value: "Germany", text: "Germany", description: "AVAILABLE" },
    { key: "GH", value: "Ghana", text: "Ghana" },
    { key: "GI", value: "Gibraltar", text: "Gibraltar" },
    { key: "GR", value: "Greece", text: "Greece", description: "AVAILABLE" },
    { key: "GL", value: "Greenland", text: "Greenland" },
    { key: "GD", value: "Grenada", text: "Grenada" },
    { key: "GP", value: "Guadeloupe", text: "Guadeloupe" },
    { key: "GU", value: "Guam", text: "Guam" },
    { key: "GT", value: "Guatemala", text: "Guatemala" },
    { key: "GN", value: "Guinea", text: "Guinea" },
    { key: "GW", value: "Guinea Bissau", text: "Guinea Bissau" },
    { key: "GY", value: "Guyana", text: "Guyana" },
    { key: "HT", value: "Haiti", text: "Haiti" },
    { key: "HM", value: "Heard Island And McDonald Islands", text: "Heard Island And McDonald Islands" },
    { key: "HN", value: "Honduras", text: "Honduras" },
    { key: "HK", value: "Hong Kong", text: "Hong Kong" },
    { key: "HU", value: "Hungary", text: "Hungary" },
    { key: "IS", value: "Iceland", text: "Iceland" },
    { key: "IN", value: "India", text: "India" },
    { key: "ID", value: "Indonesia", text: "Indonesia" },
    { key: "IR", value: "Iran", text: "Iran" },
    { key: "IQ", value: "Iraq", text: "Iraq" },
    { key: "IE", value: "Ireland", text: "Ireland", description: "AVAILABLE" },
    { key: "IL", value: "Israel", text: "Israel" },
    { key: "IT", value: "Italy", text: "Italy", description: "AVAILABLE" },
    { key: "CI", value: "Ivory Coast", text: "Ivory Coast" },
    { key: "JM", value: "Jamaica", text: "Jamaica" },
    { key: "JP", value: "Japan", text: "Japan" },
    { key: "JO", value: "Jordan", text: "Jordan" },
    { key: "KZ", value: "Kazakhstan", text: "Kazakhstan" },
    { key: "KE", value: "Kenya", text: "Kenya" },
    { key: "KI", value: "Kiribati", text: "Kiribati" },
    { key: "KP", value: "North Korea", text: "North Korea" },
    { key: "KR", value: "South Korea", text: "South Korea" },
    { key: "KW", value: "Kuwait", text: "Kuwait" },
    { key: "KG", value: "Kyrgyzstan", text: "Kyrgyzstan" },
    { key: "LA", value: "Lao", text: "Lao" },
    { key: "LV", value: "Latvia", text: "Latvia", description: "AVAILABLE" },
    { key: "LB", value: "Lebanon", text: "Lebanon" },
    { key: "LS", value: "Lesotho", text: "Lesotho" },
    { key: "LR", value: "Liberia", text: "Liberia" },
    { key: "LY", value: "Libyan", text: "Libyan" },
    { key: "LI", value: "Liechtenstein", text: "Liechtenstein" },
    { key: "LT", value: "Lithuania", text: "Lithuania", description: "AVAILABLE" },
    { key: "LU", value: "Luxembourg", text: "Luxembourg", description: "AVAILABLE" },
    { key: "MO", value: "Macao", text: "Macao" },
    { key: "MK", value: "Macedonia", text: "Macedonia" },
    { key: "MG", value: "Madagascar", text: "Madagascar" },
    { key: "MW", value: "Malawi", text: "Malawi" },
    { key: "MY", value: "Malaysia", text: "Malaysia" },
    { key: "MV", value: "Maldives", text: "Maldives" },
    { key: "ML", value: "Mali", text: "Mali" },
    { key: "MT", value: "Malta", text: "Malta", description: "AVAILABLE" },
    { key: "MH", value: "Marshall Islands", text: "Marshall Islands" },
    { key: "MQ", value: "Martinique", text: "Martinique" },
    { key: "MR", value: "Mauritania", text: "Mauritania" },
    { key: "MU", value: "Mauritus", text: "Mauritus" },
    { key: "YT", value: "Mayotte", text: "Mayotte" },
    { key: "MX", value: "Mexico", text: "Mexico" },
    { key: "FM", value: "Micronesia", text: "Micronesia" },
    { key: "MD", value: "Moldova", text: "Moldova" },
    { key: "MC", value: "Monaco", text: "Monaco" },
    { key: "MN", value: "Mongolia", text: "Mongolia" },
    { key: "MS", value: "Montserrat", text: "Montserrat" },
    { key: "MA", value: "Morocco", text: "Morocco" },
    { key: "MZ", value: "Mozambique", text: "Mozambique" },
    { key: "MM", value: "Myanmar", text: "Myanmar" },
    { key: "NA", value: "Namibia", text: "Namibia" },
    { key: "NR", value: "Nauru", text: "Nauru" },
    { key: "NP", value: "Nepal", text: "Nepal" },
    { key: "NL", value: "Netherlands", text: "Netherlands", description: "AVAILABLE" },
    { key: "AN", value: "Netherlands Antilles", text: "Netherlands Antilles" },
    { key: "NC", value: "New Caledonia", text: "New Caledonia" },
    { key: "NZ", value: "New Zealand", text: "New Zealand" },
    { key: "NI", value: "Nicaragua", text: "Nicaragua" },
    { key: "NE", value: "Niger", text: "Niger" },
    { key: "NG", value: "Nigeria", text: "Nigeria" },
    { key: "NU", value: "Niue", text: "Niue" },
    { key: "NF", value: "Norfolk Island", text: "Norfolk Island" },
    { key: "MP", value: "Northern Mariana Islands", text: "Northern Mariana Islands" },
    { key: "NO", value: "Norway", text: "Norway" },
    { key: "OM", value: "Oman", text: "Oman" },
    { key: "PK", value: "Pakistan", text: "Pakistan" },
    { key: "PW", value: "Palau", text: "Palau" },
    { key: "PS", value: "Palestinian Territory", text: "Palestinian Territory" },
    { key: "PA", value: "Panama", text: "Panama" },
    { key: "PG", value: "Papua New Guinea", text: "Papua New Guinea" },
    { key: "PY", value: "Paraguay", text: "Paraguay" },
    { key: "PE", value: "Peru", text: "Peru" },
    { key: "PH", value: "Philippines", text: "Philippines" },
    { key: "PN", value: "Pitcairn", text: "Pitcairn" },
    { key: "PL", value: "Poland", text: "Poland" },
    { key: "PT", value: "Portugal", text: "Portugal", description: "AVAILABLE" },
    { key: "PR", value: "Puerto Rico", text: "Puerto Rico" },
    { key: "QA", value: "Qatar", text: "Qatar" },
    { key: "RE", value: "Reunion", text: "Reunion" },
    { key: "RO", value: "Romania", text: "Romania" },
    { key: "RU", value: "Russian Federation", text: "Russian Federation" },
    { key: "RW", value: "Rwanda", text: "Rwanda" },
    { key: "SH", value: "Saint Helena", text: "Saint Helena" },
    { key: "KN", value: "Saint Kitts And Nevis", text: "Saint Kitts And Nevis" },
    { key: "LC", value: "Saint Lucia", text: "Saint Lucia" },
    { key: "PM", value: "Saint Pierre And Miquelon", text: "Saint Pierre And Miquelon" },
    { key: "VC", value: "Saint Vincent And The Grenadines", text: "Saint Vincent And The Grenadines" },
    { key: "WS", value: "Samoa", text: "Samoa" },
    { key: "SM", value: "San Marin", text: "San Marin" },
    { key: "ST", value: "Sao Tome And Principe", text: "Sao Tome And Principe" },
    { key: "SA", value: "Saudi Arabia", text: "Saudi Arabia" },
    { key: "SN", value: "Senegal", text: "Senegal" },
    { key: "CS", value: "Serbia And Montenegro", text: "Serbia And Montenegro" },
    { key: "SC", value: "Seychelles", text: "Seychelles" },
    { key: "SL", value: "Sierra Leone", text: "Sierra Leone" },
    { key: "SG", value: "Singapore", text: "Singapore" },
    { key: "SK", value: "Slovakia", text: "Slovakia", description: "AVAILABLE" },
    { key: "SI", value: "Slovenia", text: "Slovenia", description: "AVAILABLE" },
    { key: "SB", value: "Solomon Islands", text: "Solomon Islands" },
    { key: "SO", value: "Somalia", text: "Somalia" },
    { key: "ZA", value: "South Africa", text: "South Africa" },
    {
      key: "GS",
      value: "South Georgia And The South Sandwich Islands",
      text: "South Georgia And The South Sandwich Islands",
    },
    { key: "SP", value: "Spain", text: "Spain", description: "AVAILABLE" },
    { key: "LK", value: "Sri Lanka", text: "Sri Lanka" },
    { key: "SD", value: "Sudan", text: "Sudan" },
    { key: "SR", value: "Suriname", text: "Suriname" },
    { key: "SJ", value: "Svalbard And Jan Mayen", text: "Svalbard And Jan Mayen" },
    { key: "SZ", value: "Swaziland", text: "Swaziland" },
    { key: "SE", value: "Sweden", text: "Sweden" },
    { key: "CH", value: "Switzerland", text: "Switzerland" },
    { key: "SY", value: "Syrian Arab Republic", text: "Syrian Arab Republic" },
    { key: "TW", value: "Taiwan", text: "Taiwan" },
    { key: "TJ", value: "Tajikistan", text: "Tajikistan" },
    { key: "TZ", value: "Tanzania", text: "Tanzania" },
    { key: "TH", value: "Thailand", text: "Thailand" },
    { key: "TL", value: "Timor Leste", text: "Timor Leste" },
    { key: "TG", value: "Togo", text: "Togo" },
    { key: "TK", value: "Tokelau", text: "Tokelau" },
    { key: "TO", value: "Tonga", text: "Tonga" },
    { key: "TT", value: "Trinidad And Tobago", text: "Trinidad And Tobago" },
    { key: "TN", value: "Tunisia", text: "Tunisia" },
    { key: "TR", value: "Turkey", text: "Turkey" },
    { key: "TM", value: "Turkmenistan", text: "Turkmenistan" },
    { key: "TC", value: "Turks And Caicos", text: "Turks And Caicos", description: "AVAILABLE" },
    { key: "TV", value: "Tuvalu", text: "Tuvalu" },
    { key: "UG", value: "Uganda", text: "Uganda" },
    { key: "UA", value: "Ukraine", text: "Ukraine" },
    { key: "AE", value: "United Arab Emirates", text: "United Arab Emirates" },
    { key: "GB", value: "United Kingdom", text: "United Kingdom", description: "AVAILABLE" },
    { key: "US", value: "United States", text: "United States", description: "AVAILABLE" },
    { key: "UM", value: "United States Minor Outlying Islands", text: "United States Minor Outlying Islands" },
    { key: "UY", value: "Uruguay", text: "Uruguay" },
    { key: "UZ", value: "Uzbekistan", text: "Uzbekistan" },
    { key: "VU", value: "Vanuatu", text: "Vanuatu" },
    { key: "VA", value: "Vatican City State", text: "Vatican City State" },
    { key: "VE", value: "Venezuela", text: "Venezuela" },
    { key: "VN", value: "Vietnam", text: "Vietnam" },
    { key: "VG", value: "British Virgin Islands", text: "Virgin Islands", description: "AVAILABLE" },
    { key: "VI", value: "US Virgin Islands", text: "Virgin Islands" },
    { key: "WF", value: "Wallis And Futuna", text: "Wallis And Futuna" },
    { key: "EH", value: "Western Sahara", text: "Western Sahara" },
    { key: "YE", value: "Yemen", text: "Yemen" },
    { key: "ZM", value: "Zambia", text: "Zambia" },
    { key: "ZW", value: "Zimbabwe", text: "Zimbabwe" },
  ];

  getAll = () => this.countries;

  findByCode = (code: string) => this.countries.find((country) => country.key.toUpperCase() === code.toUpperCase());

  findByName = (name: string) => this.countries.find((country) => country.value.toLowerCase() === name.toLowerCase());

  getByBrowserConfiguration = (): Country => {
    const browserCountry = navigator?.language?.split("-")?.[1]?.toLowerCase() || "us";
    return this.countries.find((country) => country.key.toLowerCase() === browserCountry.toLowerCase())!;
  };
}
