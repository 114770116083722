module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"3861ced931d244209e6f3fcee58f5d2e","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
