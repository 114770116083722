import { StatesRepository } from "../../domain/localization/StatesRepository";

export class InMemoryStatesRepository implements StatesRepository {
  states = {
    US: [
      { key: "AL", value: "AL", text: "AL" },
      { key: "AK", value: "AK", text: "AK" },
      { key: "AS", value: "AS", text: "AS" },
      { key: "AZ", value: "AZ", text: "AZ" },
      { key: "AR", value: "AR", text: "AR" },
      { key: "CA", value: "CA", text: "CA" },
      { key: "CO", value: "CO", text: "CO" },
      { key: "CT", value: "CT", text: "CT" },
      { key: "DE", value: "DE", text: "DE" },
      { key: "DC", value: "DC", text: "DC" },
      { key: "FM", value: "FM", text: "FM" },
      { key: "FL", value: "FL", text: "FL" },
      { key: "GA", value: "GA", text: "GA" },
      { key: "GU", value: "GU", text: "GU" },
      { key: "HI", value: "HI", text: "HI" },
      { key: "ID", value: "ID", text: "ID" },
      { key: "IL", value: "IL", text: "IL" },
      { key: "IN", value: "IN", text: "IN" },
      { key: "IA", value: "IA", text: "IA" },
      { key: "KS", value: "KS", text: "KS" },
      { key: "KY", value: "KY", text: "KY" },
      { key: "LA", value: "LA", text: "LA" },
      { key: "ME", value: "ME", text: "ME" },
      { key: "MH", value: "MH", text: "MH" },
      { key: "MD", value: "MD", text: "MD" },
      { key: "MA", value: "MA", text: "MA" },
      { key: "MI", value: "MI", text: "MI" },
      { key: "MN", value: "MN", text: "MN" },
      { key: "MS", value: "MS", text: "MS" },
      { key: "MO", value: "MO", text: "MO" },
      { key: "MT", value: "MT", text: "MT" },
      { key: "NE", value: "NE", text: "NE" },
      { key: "NV", value: "NV", text: "NV" },
      { key: "NH", value: "NH", text: "NH" },
      { key: "NJ", value: "NJ", text: "NJ" },
      { key: "NM", value: "NM", text: "NM" },
      { key: "NY", value: "NY", text: "NY" },
      { key: "NC", value: "NC", text: "NC" },
      { key: "ND", value: "ND", text: "ND" },
      { key: "MP", value: "MP", text: "MP" },
      { key: "OH", value: "OH", text: "OH" },
      { key: "OK", value: "OK", text: "OK" },
      { key: "OR", value: "OR", text: "OR" },
      { key: "PW", value: "PW", text: "PW" },
      { key: "PA", value: "PA", text: "PA" },
      { key: "PR", value: "PR", text: "PR" },
      { key: "RI", value: "RI", text: "RI" },
      { key: "SC", value: "SC", text: "SC" },
      { key: "SD", value: "SD", text: "SD" },
      { key: "TN", value: "TN", text: "TN" },
      { key: "TX", value: "TX", text: "TX" },
      { key: "UT", value: "UT", text: "UT" },
      { key: "VT", value: "VT", text: "VT" },
      { key: "VI", value: "VI", text: "VI" },
      { key: "VA", value: "VA", text: "VA" },
      { key: "WA", value: "WA", text: "WA" },
      { key: "WV", value: "WV", text: "WV" },
      { key: "WI", value: "WI", text: "WI" },
      { key: "WY", value: "WY", text: "WY" },
    ],
  };

  getAllCountryStates = (country: string) => this.states[country] || [];
}
