exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-page-tsx": () => import("./../../../src/pages/CheckoutPage.tsx" /* webpackChunkName: "component---src-pages-checkout-page-tsx" */),
  "component---src-pages-common-page-tsx": () => import("./../../../src/pages/CommonPage.tsx" /* webpackChunkName: "component---src-pages-common-page-tsx" */),
  "component---src-pages-forgot-password-page-tsx": () => import("./../../../src/pages/ForgotPasswordPage.tsx" /* webpackChunkName: "component---src-pages-forgot-password-page-tsx" */),
  "component---src-pages-login-page-tsx": () => import("./../../../src/pages/LoginPage.tsx" /* webpackChunkName: "component---src-pages-login-page-tsx" */),
  "component---src-pages-page-tsx": () => import("./../../../src/pages/Page.tsx" /* webpackChunkName: "component---src-pages-page-tsx" */),
  "component---src-pages-profile-page-tsx": () => import("./../../../src/pages/ProfilePage.tsx" /* webpackChunkName: "component---src-pages-profile-page-tsx" */),
  "component---src-pages-set-password-page-tsx": () => import("./../../../src/pages/SetPasswordPage.tsx" /* webpackChunkName: "component---src-pages-set-password-page-tsx" */),
  "component---src-pages-subscription-page-tsx": () => import("./../../../src/pages/SubscriptionPage.tsx" /* webpackChunkName: "component---src-pages-subscription-page-tsx" */),
  "component---src-pages-up-sells-page-tsx": () => import("./../../../src/pages/UpSellsPage.tsx" /* webpackChunkName: "component---src-pages-up-sells-page-tsx" */)
}

